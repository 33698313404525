import '@popperjs/core';
import '@popperjs/core';
import 'bootstrap';

import Cookies from 'js-cookie';

import { COOKIES } from '@/shared/cookies';

import { setDeviceId } from '@/front/device';
import '@/front/resize';
import '@/front/tooltip';

document.addEventListener('DOMContentLoaded', () => {
  setDeviceId();

  window.addEventListener('scroll', () => {
    document.body.classList.toggle('scrolled', window.scrollY >= 50);
  });

  const planIntervalSwitch = document.getElementById('plan-interval-switch') as HTMLInputElement | undefined;

  const plansMonth = document.getElementById('plans-month') as HTMLDivElement | undefined;
  const plansYear = document.getElementById('plans-year') as HTMLDivElement | undefined;

  if (planIntervalSwitch && plansMonth && plansYear) {
    planIntervalSwitch.addEventListener('click', () => {
      plansMonth.classList.toggle('active', !planIntervalSwitch.checked);
      plansYear.classList.toggle('active', planIntervalSwitch.checked);
    });
  }

  const cookieConsentDiv = document.getElementById('cookie-consent');

  if (cookieConsentDiv) {
    const cookieConsent = Cookies.get(COOKIES.COOKIE_CONSENT.name) === 'true';

    if (!cookieConsent) {
      cookieConsentDiv.classList.remove('d-none');

      const cookieConsentButton = cookieConsentDiv.querySelector('.ok.btn') as HTMLButtonElement;

      cookieConsentButton.addEventListener('click', () => {
        Cookies.set(COOKIES.COOKIE_CONSENT.name, 'true');

        cookieConsentDiv.classList.add('d-none');
      });
    }
  }
});

const w = window as any;

w.signUpSubmit = (e: MouseEvent) => {
  const form = document.getElementById('signUpForm') as HTMLFormElement | null;

  if (w.grecaptcha && form) {
    if (form.reportValidity()) {
      e.preventDefault();
      w.grecaptcha.execute();
    }
  }
};

w.signUpRecaptcha = (token: string) => {
  const form = document.getElementById('signUpForm') as HTMLFormElement | null;
  const testInput = form?.querySelector('input[name="test"]') as HTMLInputElement | null;
  const tokenInput = form?.querySelector('input[name="recaptchaToken"]') as HTMLInputElement | null;
  const fathomEventIdInput = form?.querySelector('input[name="fathomEventId"]') as HTMLInputElement | null;
  const submitButton = document.getElementById('signUpSubmitButton') as HTMLButtonElement | null;

  if (token && form && testInput && tokenInput && fathomEventIdInput && submitButton) {
    submitButton.disabled = true;

    const test = testInput.value === 'true';
    const fathomEventId = fathomEventIdInput.value;

    if (w.fathom) {
      if (!test) {
        w.fathom.trackGoal(fathomEventId, 0);
      } else {
        console.log('[TEST] Track Fathom Trial', { fathomEventId });
      }
    }

    if (w.gtag) {
      if (!test) {
        w.gtag('event', 'trial'); // Google Analytics
        w.gtag('event', 'conversion', { send_to: 'AW-11304961816/CsnICNif-ZYaEJiO0I4q' }); // Google Ads
      } else {
        console.log('[TEST] Track Google Trial');
      }
    }

    if (w.lintrk) {
      if (!test) {
        w.lintrk('track', { conversion_id: 18872586 });
      } else {
        console.log('[TEST] Track LinkedIn Trial');
      }
    }

    console.log('[TEST] Recaptcha token', { token });

    tokenInput.value = token;

    if (test) {
      alert('[TEST] Tracking complete');
    }

    form.submit();
  }
};
