import { debounce } from 'debounce';

window.addEventListener(
  'resize',
  debounce(() => resize()),
);

document.addEventListener('DOMContentLoaded', () => resize());

const resize = () => {
  const navbar = document.getElementById('navbar');
  const subNavbar = document.getElementById('subNavbar');

  if (navbar) {
    document.body.style.paddingTop = `${navbar.clientHeight + (subNavbar?.clientHeight ?? 0)}px`;
  }

  const offcanvasIframe = document.querySelector('#offcanvas iframe')! as HTMLIFrameElement | null;

  if (offcanvasIframe) {
    offcanvasIframe.style.height = `${window.innerHeight}px`;
  }
};
